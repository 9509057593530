import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TextImage from "../components/textImage"
import ImageColumn from "../components/imageColumn"

const BreadAndHops = () => {
  const data = useStaticQuery(graphql`
    query {
      index1: file(relativePath: { eq: "bread-and-hops-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index2: file(relativePath: { eq: "bread-and-hops-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      index3: file(relativePath: { eq: "bread-and-hops-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Bread and Hops"/>
      <TextImage headline={"Bread and Hops."} source={data.index1.childImageSharp.fluid} source_position={"top"}>
        <div data-srgrid-row="">
          <div data-srgrid-col="xs-24 l-11 l-offset-1">
            <p>
              Warum eigentlich immer geschnitten genießen?
              <br/><br/>
              Wir hatten die Idee, gemeinsam mit den Jungs von Al Mania ein Bier zu kreieren, das durch Zugabe unseres
              Brotes zu einem mild aromatischen Craft Bier wird. Die Entscheidung fiel da schnell auf unser Aschauer, da
              dieses Brot ausschließlich mit Sauerteig gelockert wird und über Nacht seine milde Säure entwickelt. Diese
              reiht sich ideal in das Gesamtaroma ein.
            </p>
          </div>
          <div data-srgrid-col="xs-24 l-11 l-offset-1">
            <p>
              Den Rest, machen die Bonner Jungs in ihrer Brauerei: maischen,
              brauen, abfüllen. Unser Bread&Hops ist nicht nur das Ergebnis einer tollen Kombination aus Brot und Malz.
              Sie bringt auch zwei Bonner Unternehmen und deren Handwerkstradition zusammen deren Ergebnis sich durch
              aus trinken lassen kann.
            </p>
          </div>
        </div>
      </TextImage>
      <ImageColumn source_left={data.index2.childImageSharp.fluid}
                   figcaption_left={"Grundlage des Bieres ist unser Aschauer. <br/> Unser Weizenmischbrot auf Basis eines Sauerteigs."}
                   figcaption_right={"Zweizeilige. <br/> Bildbeschreibung."}
                   source_right={data.index3.childImageSharp.fluid}/>
    </Layout>
  )
}


export default BreadAndHops
